html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#main {
  height: calc(100vh - 48px);
  overflow: auto;
  scrollbar-color: var(--colorNeutralStroke1Pressed, rgba(0, 0, 0, 0.2)) transparent;
  scrollbar-gutter: stable;
}

div {
  scrollbar-color: var(--colorNeutralStroke1Pressed, rgba(0, 0, 0, 0.2)) transparent;
}
